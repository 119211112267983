<template>
  <div class="item">
    <div ref="item" class="item__wrap">
      <div
        v-if="item.preview.image_mobile || item.preview.src"
        class="item__image"
      >
        <div class="item__image-wrap">
          <img
            :src="item.preview.image_mobile || item.preview.src"
            :alt="item.title"
            width="392"
            height="510"
            loading="lazy"
          />
        </div>
        <div class="item__date" v-html="dateFormat(item.published_at)" />
        <div v-if="isVideo" class="item__play btn-play">
          <icon icon="play" />
        </div>
      </div>
      <div class="item__content">
        <div class="item__title h6">
          <a
            href="javascript:void(0)"
            class="stretched-link cursor-zoom"
            @click="
              $vfm.show({
                component: 'PopupGallery',
                bind: {
                  title: $filters.dateFormat(item.published_at),
                  img: img,
                  isInnerText: true,
                  ratioPercent: true,
                },
              })
            "
          >
            {{ $filters.dateFormat(item.published_at) }}
          </a>
        </div>
        <div v-if="false" class="item__text" v-html="item.short_content" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      img: [],
      isVideo: false,
    };
  },
  computed: {
    dateFormat() {
      return (v) => {
        const months = [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ];
        const date = new Date(v * 1000);
        return months[date.getMonth()] + "<br />" + date.getFullYear();
      };
    },
  },
  async mounted() {
    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            start: "top 70%",
            end: "center center",
            scrub: 1.5,
          },
        })
        .from(
          this.$refs.item,
          {
            y: 100,
          },
          0
        )
        .from(
          this.$refs.item.querySelector(".item__image img"),
          {
            scale: 1.2,
          },
          0
        );
    }
    try {
      const { data } = await axios.get(
        this.$endpoint + "publication/" + this.item.id + "/?type=json"
      );
      const preview = this.item?.preview;
      if (!data) {
        throw new Error();
      }
      const blocks = data.data.content[0].body.blocks;
      if (blocks[0].type === "image") {
        this.img.push({ ...preview, src: preview.source });
      }
      if (blocks[0].type === "embed") {
        this.isVideo = true;
      }
      for (const block of blocks) {
        const data = block.data;
        if (!data) {
          continue;
        }
        switch (block.type) {
          case "image":
            this.img.push({
              src: data[0].source,
              image_desktop: data[0].image_desktop,
              image_tablet: data[0].image_tablet,
              image_mobile: data[0].image_mobile,
              text: data[0].caption,
            });
            break;
          case "embed":
            this.img.push({
              isVideo: true,
              src:
                data.embed +
                "?version=3&autoplay=1&controls=1&disablekb=1&fs=0&modestbranding=1&loop=1&rel=0",
            });
            break;
        }
      }
    } catch (err) {
      //
    } finally {
      //
    }
  },
};
</script>

<style scoped>
.item {
  position: relative;
  width: 496px;
  height: 286px;
  margin-bottom: 115px;
}

.item__wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.item__image {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 66.666%;
}

.item__image-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.item__image img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item__content {
  margin-top: 15px;
}

.item__title {
  margin-bottom: 5px;
}

.item__title:last-child {
  margin-bottom: 0;
}

.item__title a {
  color: inherit;
  text-decoration: none;
}

body.-notouch .item__title a:hover {
  color: #205640;
}

.item__date {
  position: absolute;
  top: 50%;
  left: 100%;
  width: 135px;
  margin-top: -22px;
  padding: 4px 0;
  background: #fff;
  color: #9c9b9b;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
}

.item__play {
  position: absolute;
  top: 50%;
  left: 50%;
}

body.-notouch .item:hover .item__play {
  background: linear-gradient(180deg, #62a650, #a0c994);
}

body.-notouch .item:active .item__play {
  background: linear-gradient(180deg, #498c36, #86af7a);
}

@media (max-width: 1279px) {
  .item {
    width: 356px;
    height: 205px;
  }

  .item__date {
    width: 121px;
    font-size: 11px;
  }
}

@media (max-width: 991px) {
  .item {
    width: 250px;
    height: 142px;
    margin-bottom: 80px;
  }

  .item__date {
    width: 93px;
  }
}

@media (max-width: 767px) {
  .item {
    width: 100%;
    height: fit-content;
    margin-bottom: 43px;
  }

  .item__image {
    height: 300px;
  }

  .item__date {
    display: none;
  }
}

@media (max-width: 575px) {
  .item__image {
    height: 225px;
  }
}

@media (max-width: 375px) {
  .item__image {
    height: 189px;
  }
}
</style>
