<template>
  <Head>
    <title>Ход строительства {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Актуальные фотографии хода строительства и 2 онлайн-камеры жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
    <meta property="og:title" :content="'Ход строительства ' + $titleEnd" />
    <meta
      property="og:description"
      content="Актуальные фотографии хода строительства и 2 онлайн-камеры жилого комплекса VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА."
    />
  </Head>
  <main class="main mb-0">
    <div class="container">
      <Breadcrumbs
        :links="[
          { name: 'Home', text: 'Главная' },
          { text: 'Ход строительства' },
        ]"
      />
      <h1 class="page-title h2">Ход строительства</h1>
      <div class="construction">
        <div class="btns d-md-none">
          <a
            href="javascript:void(0)"
            class="btn btn-default"
            @click="$vfm.show({ component: 'PopupLive' })"
            >Онлайн камера</a
          >
        </div>
        <div
          v-if="items.length > 0"
          class="list"
          :class="{ odd: items.length % 2 === 0 }"
        >
          <ConstructionItem
            v-for="(item, index) in items"
            :key="item.id"
            :index="index"
            :item="item"
          />
        </div>
      </div>
    </div>
    <HomeChoose v-if="items.length > 0" />
  </main>
</template>

<script>
import axios from "axios";
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import PlayCamera from "@/components/PlayCamera.vue";
import ConstructionItem from "@/components/construction/ConstructionItem.vue";
import HomeChoose from "@/components/home/Choose.vue";
import { Head } from "@vueuse/head";

export default {
  components: {
    Head,
    Breadcrumbs,
    PlayCamera,
    ConstructionItem,
    HomeChoose,
  },
  data() {
    return {
      items: [],
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        this.$endpoint +
          "publications?project=9&category=2&language=ru&page=1&per_page=999"
      );
      this.items = response.data.data;
    } catch (err) {
      this.$Progress.fail();
    } finally {
      this.$Progress.finish();
    }
  },
};
</script>

<style scoped>
.construction {
  --play-btn-w: 178px;

  position: relative;
  min-height: 300px;
}

.play-btn__wrap {
  position: absolute;
  top: 0;
  right: 5px;
  width: var(--plan-btn-w);
  z-index: 1;
  height: 100%;
}

:deep(.play-btn__wrap .play-camera) {
  position: sticky;
  top: 130px;
}

.btns {
  margin-bottom: 45px;
  text-align: center;
}

.list {
  position: relative;
  max-width: calc(100% - (var(--play-btn-w) / 2 + 8px));
  margin-bottom: 120px;
}

@media (max-width: 991px) {
  .list::before {
    bottom: 240px;
  }
}

@media (min-width: 768px) {
  .list.odd {
    padding-bottom: 217px;
  }

  .list::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 300px;
    left: 50%;
    width: 1px;
    background: #e5e5e5;
  }

  .list :deep(.item:nth-child(even)) {
    position: absolute;
    right: 0;
    transform: translateY(calc(-50% - 57px));
  }

  .list :deep(.item:nth-child(even) .item__date) {
    right: 100%;
    left: auto;
  }

  .list :deep(.item:last-child) {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .list {
    max-width: 100%;
    margin-bottom: 65px;
  }
}
</style>
