<template>
  <section class="home-block py-0 text-white">
    <div class="background">
      <div ref="background" class="video-background">
        <img
          :src="require('@i/video/home-choose.jpg')"
          loading="lazy"
          width="1288"
          height="680"
          alt=""
        />
        <video
          class="lazy"
          autoplay
          muted
          loop
          playsinline
          preload="none"
          :data-src="require('@i/video/home-choose.mp4')"
          src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
        />
      </div>
    </div>
    <div class="content">
      <div ref="content" class="container">
        <div ref="subtitle" class="h5 subtitle">Подбор квартиры</div>
        <div ref="title" class="h2 title">Выберите квартиру своей мечты</div>
        <div ref="text" class="text">
          Функциональные студии 21-40&nbsp;кв.&nbsp;м, разнообразие семейных
          планировок от&nbsp;37&nbsp;до&nbsp;139&nbsp;кв.&nbsp;м.&nbsp;85%
          квартир имеют исключительные видовые&nbsp;характеристики
        </div>
        <div ref="buttons" class="btns-list d-md-flex justify-content-center">
          <div class="btns-list__item">
            <router-link :to="{ name: 'PlanArchive' }" class="btn btn-default">
              Выбрать квартиру
            </router-link>
          </div>
          <div class="btns-list__item">
            <button class="btn btn-transparent" data-popup-with-chats data-popup-with-chats-title="Связаться со&nbsp;специалистом">
              Связаться со&nbsp;специалистом
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { lazyVideo, is_touch } from "@/helpers.js";
import { gsap } from "gsap";
export default {
  mounted() {
    lazyVideo(this.$el);

    if (!is_touch()) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: this.$el,
            scrub: true,
          },
        })
        .fromTo(
          this.$refs.background,
          { y: "-25%" },
          {
            y: "25%",
            ease: "none",
          }
        );

      const animatedItems = [
        this.$refs.subtitle,
        this.$refs.title,
        this.$refs.text,
        this.$refs.buttons,
      ];

      gsap.from(animatedItems, {
        y: 150,
        overwrite: "auto",
        scrollTrigger: {
          trigger: this.$el,
          start: "top 70%",
          end: "center center",
          scrub: 1.5,
        },
        stagger: 0.1,
      });
    }
  },
};
</script>

<style scoped>
.home-block {
  text-align: center;
}

body.-notouch .video-background {
  top: -25%;
  bottom: -25%;
  height: auto;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(63 68 64 / 0.3);
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 720px;
  padding: 90px 0;
}

.subtitle {
  margin-bottom: 57px;
}

.title {
  max-width: 700px;
  margin: 0 auto 40px;
}

.text {
  max-width: 600px;
  margin: 0 auto;
}

.btns-list {
  margin-top: 55px;
}

@media (max-width: 1279px) {
  .content {
    min-height: 700px;
  }
}
@media (max-width: 991px) {
  .content {
    min-height: 680px;
  }

  .title {
    max-width: 650px;
  }
}
@media (max-width: 767px) {
  .content {
    min-height: auto;
    padding: 65px 0;
  }

  .subtitle {
    margin-bottom: 27px;
  }

  .title {
    margin-bottom: 27px;
  }

  .btns-list {
    margin-top: 40px;
  }

  .btns-list__item {
    margin: 0 0 10px;
  }

  .btns-list__item:last-child {
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .btns-list .btn-transparent {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
